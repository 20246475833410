
.search-parent-container{
    position: absolute !important;
    right: -20px;
    transition: width .3s linear;
    top: 10px;
    overflow: hidden;
    width: 0;
    z-index: 1;

    &.open{
        transition: width .3s linear;
        width: 640px;
        overflow: visible;

        .search-icon{
            left: 0;
            top: 15px;
            right: unset;
            z-index: 1;
        }
    }

    .search-container.outer{
        .inner{
            height: 48px;
            width: 100%;
            
            input{
                border: 1px solid $turquoise;
                color: $blue;
                font-family: 'unit-slab';
                font-size: 16px;
                font-weight: bold;
                min-height: 48px;
                min-width: 100%;
                padding-left: 43px;
                padding-top: 5px;
                
                &::placeholder{
                    color: $grey;
                    font-family: 'unit-slab';
                    font-size: 16px;
                    font-weight: bold;
                }
                
                &:active, &:focus{
                    
                    outline: none;
                }
            }  
            
        }

        .close-icon{
            cursor: pointer;
            position: absolute;
            top: 12px;
            right: 20px;
        }  
    }

    .search-icon{
        position: absolute;
        top: 12px;
        left: 0;
    }

    .search-results-container{
        background-color: $white;
        box-shadow: 0 0 40px 0 rgba(0,0,0,0.1);
        max-height: 640px;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar{
            width: 10px;
        }

        &::-webkit-scrollbar-track{
            background-color: #F6F7F9;
        }
        
        &::-webkit-scrollbar-thumb{
            background-color: rgba(59, 158, 147, .25);
        }

        .search-result{
            padding: 20px 43px 12px 43px;

            &:first-child{
                padding-top: 36px;
            }

            &:last-child{
                padding-bottom: 65px;
            }

            a{
                text-decoration: none;
            }

            .result-type{
                color: $turquoise;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
            }

            .content{    
                .title{
                    color: $blue;
                    font-family: "unit-slab";
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 24px;
                }

                .excerpt{
                    color: $darker-font;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }


        }
    }
}

.search-icon{
    cursor: pointer;
}

@include media-breakpoint-down(lg){
    .search-parent-container{
        right: unset;
        width: calc(100% - 100px);
        height: 48px;
        bottom: 50px;
        top: unset;
        left: 50px;
        max-width: 560px;

        .search-container{
            display: block !important;
        }

        &.search-open{
            height: 100vh;
            left: 15px;
            position: fixed !important;
            top: 15px;
            max-width: unset;
            width: calc(100vw - 30px);

            .search-results-container{
                background-color: #F6F6F6;
                height: 100vh;
                padding-bottom: 300px;
                max-height: unset;

                .search-result{
                    background-color: $white;
                    padding: 20px;
                }
            }
        }
    }

    .search-icon.mobile{
        display: none !important;
    }
}