.callout-block{
    margin-bottom: 60px;
    margin-top: 60px;
    .text-container{
        background-color: $grey-l5;
        border: 5px solid $blue;
        padding: 20px 28px;
        font-size: 1.8rem;
        p{
            margin-bottom: 0;
        }
    }
}