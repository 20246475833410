$white: #ffffff;
$white-30: #ffffff30;
$white-05: #ffffff05;
$black: #000000;

$font-color: #215973;
$darker-font: #143646;

$blue-d2: #0A3143;
$blue-d1: #11394C;
$blue: #194458;
$blue-60: rgba(25, 68, 88, 0.6);
$blue-l1: #215973;
$blue-l2: #3081A7;

$green: #ACD072;

$turquoise-d2: #1F544E;
$turquoise-d1: #36948A;
$turquoise: #3B9E93;
$turquoise-l1: #31B9AB;
$turquoise-l2: #74CBC1;
$yellow: #FFD100;

$grey: #789299;
$grey-l1: #E9EEEF;
$grey-l2: #F8F9FA;
$grey-l3: #F6F6F6;
$grey-l4: #ECECEC;
$grey-l5: #eeeded;
$grey-d1: #50727B;
$grey-d2: #2A4E57;
$neutral-grey: #919191;

$list-group-hover: #f3f3f4;