.page-standardpage{
    .title-block{
        padding-top: 64px;
        .align-left{
            justify-content: flex-start !important;
        }
        .title{
            margin-bottom: 32px;
        }
        .excerpt{
            line-height: 145%;
        }
    }
    .sidebar{
        min-height: 100vh;
        right: 0;
        top: 50%;
        ul{
            position: sticky;
            top: 80px;
            li{
                margin-bottom: 8px;
                height: 48px;
                a{
                    color: $grey-d1;
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 2rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg){
    .page-standardpage{
        .title-block{
            .title{
                font-size: 4.8rem;
                line-height: 115%;
            }
        }
    }    
}
@include media-breakpoint-down(md){
    .page-standardpage{
        .title-block{
            .excerpt{
                font-size: 1.6rem;
                line-height: 150%;
            }
        }
    }   
}