#pension-calculator-block{
    background-color: $turquoise;
    color: $white;
    margin-bottom: 64px;
    padding-bottom: 126px;
    padding-top: 80px;  
    >.container-fluid{
        padding: 0 113px !important;
        >.row{
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
    h2{
        margin-bottom: 64px;
    }
    .mb-60{
        margin-bottom: 60px;
    }
    .title-row{
        background-image: linear-gradient(to right, transparent, rgba(25, 68, 88, 0.1)) !important;
    }
    .inner-container:not(.rt-container){
        >.row{
            padding-left: calc(0px + (100 - 0) * ((100vw - 1080px) / (1440 - 1080))) !important;
            @media (min-width: 1440px){
                padding-left: 50px !important;
            }
        }
    }
    .inner-container{
        >.row{
            height: 68px;
            &:nth-child(odd){
                background-image: linear-gradient(to right, transparent, rgba(25, 68, 88, 0.07));
            }
        }
        &.rt-container{
            position: relative;
            &::before{
                content: '';
                position: absolute;
                background: linear-gradient(to right, rgba(255, 255, 255, 0.05), transparent);
                height: calc(100% - 68px);
                width: 100%;
                top: 0;
                left: 0;
                pointer-events: none;
            }
            >.row{
                padding-left: 6px;
                &:nth-child(odd){
                    background-image: linear-gradient(to right, rgba(25, 68, 88, 0.1), transparent);
                }
            }
            .title-row{
                background-image: linear-gradient(to right, rgba(25, 68, 88, 0.1), transparent) !important;
            }
        }
        .fixed-input-width{
            min-width: 114px;
            max-width: 157px;
        }
        .col-form-label{
            line-height: 16px;
        }
        input{
            background-color: $white-05;
            border: 2px solid $white-30;
            border-radius: 0;
            box-shadow: 0px 5px 7px rgba(25, 68, 88, 0.08);
            color: $white;
            font-size: 1.6rem;
            font-weight: 700;
            height: 42px;
            width: 100%;
            text-align: right;
            padding-right: 36px;
            padding-top: 3px;
            max-width: 180px;
            &:active, &:focus{
                outline: none;
            }
            &::placeholder{
                color: $white;
            }
        }
        p {
          top: 10px;
          right: 25px;
          position: absolute;
        }
        .form-switch{
            padding-left: 16px;
            .input-container{
                .switch-inner{
                    margin-right: -28px;
                    width: 140px;
                    &::before{
                        content: 'Nei';
                        color: $white;
                        right: 40px;
                        top: 13px;
                        font-size: 1.6rem;
                        font-weight: 700;
                        position: absolute;
                    }
                    &::after{
                        content: 'Já';
                        background-color: $white-05;
                        border: 2px solid $white-30;
                        box-shadow: 0px 5px 7px rgba(25, 68, 88, 0.08);
                        color: $white;
                        font-size: 1.6rem;
                        font-weight: 700;
                        height: 32px;
                        line-height: 30px;
                        left: 0;
                        top: 8px;
                        padding-left: 19px;
                        position: absolute;
                        width: 114px;
                        z-index: 10;
                    }
                    .lang-en & {
                        &::after {
                            content: 'Yes';
                        }
                        &::before {
                            content: 'No';
                        }
                    }
                    svg{
                        cursor: pointer;
                        position: absolute;
                        top: 3px;
                        left: -5px;
                        transition: left .15s ease-in-out;
                        z-index: 1000;
                        pointer-events: none;
                    }
                    input{
                        background-image: none;
                        box-shadow: none;
                        cursor: pointer;
                        height: 42px;
                        border: none;
                        -webkit-appearance: none;
                        float: unset;
                        margin: 5px 0 0 -2px;
                        width: 118px;
                        z-index: 100;
                    }
                    &.p-16{
                        padding-left: 32px;
                        padding-right: 16px;
                    }
                    &.checked{
                        svg{
                            left: 40%;
                            transition: left .15s ease-in-out;
                        }
                    }
                }
            }
        }
    }
    .calc-btn{
        background: linear-gradient(180deg, #194D66 0%, #194458 100%);
        border: 1px solid #0A3143;
        box-sizing: border-box;
        box-shadow: 0px 5px 7px rgba(154, 173, 183, 0.25);
        border-radius: 5px;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        height: 36px;
        width: 103px;
        &:active, &:focus{
            outline: none;
        }
        &:hover{
            background: #0A3143;
        }
    }
    .results{
        border-top: 2px solid $white;
        padding-top: 16px !important;
        &.loading{
            .text-container, .chart-container{
                opacity: .2;
            }
        }
        .text-container{
            padding-right:  96px !important;
            @media (max-width: 1360px){
                padding-right: 16px !important;
            }
            p{
                margin-bottom: 2rem;
            }
            span.asterisk{
                font-size: 1.2rem;
                margin-top: 58px;
            }
            span.result{
                font-weight: 700;
                background: rgba(25, 68, 88, 0.4);
                float: right;
            }
        }
        canvas{
            margin-top: 0;
        }
    }
}

@include media-breakpoint-down(xl){
    #pension-calculator-block{
        .inner-container{
            .form-switch{
                padding-left: 16px;
            }
        }
        .results{
            canvas{
                margin-top: 40px;
                height: 100% !important;
                max-height: 400px;
                width: auto !important;
                max-width: 100%;
            }
        }
    }
}

@include media-breakpoint-up(lg){
    #pension-calculator-block{
        .inner-container{
            &.rt-container{
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0.05), transparent);
                    height: calc(100% - 68px);
                    width: 100%;
                    top: 0;
                    left: 0;
                }
                >.row{
                    &:nth-child(odd){
                        background-image: linear-gradient(to right, rgba(25, 68, 88, 0.1), transparent);
                    }
                }
                .title-row{
                    background-image: linear-gradient(to right, rgba(25, 68, 88, 0.1), transparent) !important;
                }
            }
        }
    }

}

@include media-breakpoint-down(lg){
    #pension-calculator-block{
        >.container-fluid{
            padding: 0 24px !important;
            .inner-container{
                >.row{
                    &:nth-child(odd){
                        background-image: linear-gradient(to right, rgba(25, 68, 88, 0.07), transparent) !important;
                    }
                    .switch-inner{
                        &::before{
                            right: 36px;
                        }
                    }
                }
            }
        }
        .results{
            span.result{
                font-size: 1.6rem !important;
            }
            canvas{
                max-height: 300px;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    #pension-calculator-block{
        >.container-fluid{
            padding: 0 !important;
            input{
                padding-bottom: 0;
            }
            .switch-inner{
                margin-right: 0;
                &::before{
                    right: 40px;
                }
            }
        }
        .results{
            padding: 24px !important;
            span.result{
                display: block;
                width: fit-content;
                margin-top: 4px;
                padding-left: 3px;
                padding-right: 3px;
                float: unset !important;
            }
        }
    }
}