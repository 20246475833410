#alert-banner{
    min-height: 75px;
    background-color: #FCD94E;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    text-decoration: none;
    &:hover{
        background-color: $yellow;
    }
    .banner-title{
        color: #194458;
        font-family: "unit-slab";
        font-size: 20px;
        line-height: 1.2;
        font-weight: bold;
        margin-right: 27px;
        text-decoration: none;
    }
    .banner-text{
        color: #194458;
        font-family: "unit-slab";
        font-size: 20px;
        line-height: 1.2;
        text-decoration: none;
    }

    @include media-breakpoint-down(md){
        .banner-title{
            font-size: 16px;
        }
        .banner-text{
            font-size: 16px;
        }
    }
}
a.nodec{
    text-decoration: none;
}

.alert-banner-wrapper{
    &+header{
        #navbarNav{
            top: 160px!important;
        }
    }
}