.image-link-block{
    .image-link-row{
        column-count: 2;
        max-height: 2000px;
        @media (max-width: 1380px){
            max-height: 1600px;
        }
        .content{
            margin-bottom: 48px;
            &:nth-child(3){
                margin-top: 32px;
            }
            img{
                height: 100%;
                object-fit: cover;
                max-height: 480px;
                width: 100%;       
            }
            .text-block{
                background-color: $white;
                max-width: 528px;
                margin-top: -160px;
                padding: 48px;
                z-index: 1;
                .btn{
                    background-color: $turquoise;
                    border-radius: 4px;
                    color: $white;
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 2rem;
                    height: 48px;
                    width: 114px;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .image-link-block{
        .image-link-row{
            max-height: 1200px;
            .content{
                padding-left: 0;
                .text-block{
                    margin-top: -109px;
                    padding: 16px;
                    width: calc(100% - 16px);
                }
            }
        }
    }
}
@include media-breakpoint-down(md){
    .image-link-block{
        .image-link-row{
            column-count: 1;
            max-height: unset;
            .content{
                padding-left: 0;
                .text-block{
                    margin-top: -109px;
                    padding: 16px;
                    width: calc(100% - 16px);
                }
            }
        }
    }
}