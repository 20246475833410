footer {
    background-color: $blue;
    color: $white;
    padding: 64px;
    margin-top: 90px;

    a {
        color: $white  !important;
    }

    .top-row {
        padding: 16px 0;
        margin-bottom: 24px;
    }

    .col {
        img {
            height: 72px;
            max-width: 130px;
        }
        
    }
    .nav-item {
        a.nav-link {
            border-bottom: 1px solid $turquoise;
            cursor: pointer;

            &:hover {
                border-bottom: 1px solid $white;
            }

            h5 {
                color: $white;
                margin-bottom: 20px;
            }
        }
    }

    .opening-hours-row {
        height: 120px;
    }   
}

@include media-breakpoint-up(lg) {
    footer{
        .logo{
            img{
                margin-left: -34px;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    footer {
        height: auto;
        padding: 64px 20px 120px 20px;

        .container-fluid {
            padding: 0 !important;
        }
    }
}

@include media-breakpoint-down(md) {
    footer {
        padding: 60px 16px 30px 16px !important;

        .top-row {
            .logo {
                position: absolute;
                top: 16px;
                left: 0;

                img {
                    max-width: 166px;
                }
            }

            .location {
                .col {
                    padding: 4px 0;
                }
            }
        }

        .opening-hours-row {
            height: auto;

            .col {
                padding: 4px 16px;

                &.logo {
                    padding-left: 12px !important;
                }
            }
        }

        .content {
            .nav-item {
                margin-bottom: 16px;

                a {
                    h5 {
                        margin-bottom: 0px !important;
                    }
                }
            }
        }
    }
}