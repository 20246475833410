.single-button-block{
    margin-bottom: 64px;
    margin-top: 24px;
    a{
        background-color: $blue;
        border: none;
        border-radius: 4px;
        color: $white;
        font-size: 2rem;
        font-weight: 700;
        line-height: 48px;
        height: 48px;
        padding-left: 32px;
        padding-right: 32px;
    }
}