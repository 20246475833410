.loader {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	text-align: center;
	opacity: 1;

	.loader__wrapper {
		top: 50%;
		position: relative;
		transform: translate(0, -50%);
	}
	span {
		margin: 0 auto;
		width: 3rem;
		height: 3rem;
		border: 0.3125rem solid $white;
		display: inline-block;
		span {
			border-color: $blue;
			display: inline-block;
			background-color: $blue;
			width: 1rem;
			height: 1rem;
			margin-top: 0.375rem;
			animation-name: logoAnimate;
			animation-duration: 1500ms;
			animation-timing-function: cubic-bezier(1, -0.14, 0.29, 1.41);
			animation-iteration-count: infinite;
		}
	}

	b {
		color: $white;
		text-align: center;
		font-weight: bold;
		text-align: center;
		clear: both;
		display: block;
		font-size: 12px;
		margin-top: 5px;
	}
}

@keyframes logoAnimate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}