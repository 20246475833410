.pagination{
    li{
        border: 1px solid $grey-l1;
        height: 32px;
        margin-right: 32px;
        min-width: 32px;
        text-align: center;
        width: 32px;
        &.active{
            background-color: $turquoise;
            a{
                color: $white;
            }
        }
        &.next, &.first{
            margin-right: 16px;
        }
        &.disabled{
            img{
                opacity: .3;
            }
        }
        span, a{
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 3.2rem;
        }
    }
}