.page-iframepage {

    #pension-calculator-block {
        background-color: $white;
        color: $blue-d2;
        input {
            color: $blue-d2;
        }
        .inner-container {
            .form-switch .input-container .switch-inner {
                &:before {
                    color: $blue-d2;
                }
                &:after {
                    color: $blue-d2;
                }
            }
        }
    }

}