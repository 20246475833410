.tab-list-block{
    .tabs{
        margin-bottom: 32px;
        ul{
            li{
                a{
                    background-color: transparent !important;
                    border-top: 2px solid $grey;
                    border-radius: 0;
                    color: $grey;
                    font-size: 22px;
                    font-weight: 600;
                    padding: 16px 48px;
                    &.active{
                        border-top: 4px solid $turquoise;
                        color: $turquoise;
                    }
                    &:not(.active){
                        padding-top: 18px;
                    }
                    &:hover{
                        border-top: 2px solid $grey;
                        &.active{
                            border-top: 4px solid $turquoise;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .tab-list-block{
        .tabs{
            margin-bottom: 32px;
            ul{
                li{
                    a{
                        padding: 8px 26px;
                        &:not(.active){
                            padding-top: 10px;
                        }
                    }
                }
            }
        }
    }
}