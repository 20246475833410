.subpage-chooser-block{
    margin-bottom: 42px;
    &.stefnur{
        h4{
            font-size: 2.4rem !important;
            line-height: 135% !important;
        }
    }
    .primary{
        margin-bottom: 32px;
        .item{
            margin-bottom: 32px;
        }
        .subpage-box {
            background: $white;
            height: 100%;
            padding: 32px;
            &::after{
                background-color: $white;
                bottom: 0;
                content: '';
                height: 16px;
                left: 0;
                position: absolute;
                width: 100%;
                z-index: 1;
            }
            .title{
                color: $turquoise;
                margin-bottom: 16px;
            }
        }
    }
    .secondary{
        margin-bottom: 48px;
        .outer-container{
            width: max-content;
            .subpage-box{
                border: 2px solid $grey-l1;
                color: $grey-d1;
                font-size: 2rem;
                font-weight: 700;
                height: 48px;
                padding: 9px 32px 14px 32px;
                width: auto;
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .subpage-chooser-block{
        .col-sm-6{
            margin-bottom: 32px;
        }
    }
}
@include media-breakpoint-down(md){
    .subpage-chooser-block{
        .col-sm-6{
            margin-bottom: 8px !important;
        }
        .subpage-box {
            height: 64px !important;
            margin-bottom: 8px;
            padding: 16px !important;
            &::after{
                content: none;
            }
            svg{
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}