.table-tabs{
    border-bottom: none;
    margin-top: 32px;
    margin-bottom: 16px;
    li{
        height: 64px;
        &:hover{
            border-bottom: none;
        }
        button{
            background-color: transparent !important;
            border-radius: 0 !important;
            border: none !important;
            color: $grey;
            line-height: 2.2rem;
            padding: 16px 48px;
            &.active{
                box-shadow: inset 0px 4px 0px $turquoise;
                color: $turquoise;
            }
            &:hover{
                color: $turquoise;
            }
        }
    }
}
.table-container{
    overflow: scroll;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
    &::-webkit-scrollbar{
      width: 0 !important;
    }
    table{
        thead{
            tr{
                background-color: $turquoise;
                th{
                    color: $white;
                    padding: 16px !important;
                    vertical-align: middle;
                    background-color: transparent;
                }
            }
        }
        tr{
            box-shadow: inset 0px -1px 0px $grey-l4;
            height: 56px;
            td{
                padding: 16px !important;
                vertical-align: middle;
            }
            th{
                padding: 16px !important;
                vertical-align: middle;
                font-weight: 700;
            }
        }
        &.table > :not(:first-child) {
            border-top: none;
        }
    }
}

.table-block{
    margin-top: 30px;
    margin-bottom: 60px;
}


@include media-breakpoint-down(lg){
    .table-tabs{
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        li{
            height: 48px;
            button{
                font-size: 1.6rem;
                padding: 16px 24px;
                white-space: nowrap;
            }
        }
    }
}