header {
    nav {
        height: 112px;

        >.container-fluid {
            padding-left: 24px;
        }

        .menu-login {
            display: none;
        }

        .subnav {
            height: 0;
            overflow: hidden;
            width: 0;
        }

        .arrow {
            margin-left: auto;
        }

        .navbar-brand {
            margin-right: 3%;
        }

        .navbar-toggler {
            border: none;
            outline: none;

            &:active,
            &:focus {
                box-shadow: none;
            }

            &.open {
                span {
                    &:first-child {
                        transform: rotate(45deg) translate(4px, 5px);
                        transition: transform .2s linear;
                    }

                    &.middle {
                        width: 0;
                        transition: width .2s linear;
                    }

                    &:last-child {
                        transform: rotate(-45deg) translate(4px, -5px);
                        transition: transform .2s linear;
                    }
                }
            }

            span {
                background-color: $turquoise;
                height: 2.5px;
                margin-bottom: 4px;
                width: 21px;
                transform: rotate(0) translateY(0);
                transition: transform .2s linear, width .2s linear;
            }
        }

        #navbarNav {
            ul {
                li.nav-item {
                    display: flex;
                    align-items: center;
                    padding: 16px 24px;
                    margin-right: calc(0px + (16 - 0) * ((100vw - 992px) / (1920 - 992)));

                    a.nav-link {
                        color: $blue  !important;
                        font-size: 2.4rem;
                        white-space: nowrap;
                        font-weight: bold;

                        &.lang, &.login {
                            font-size: 1.6rem;
                        }
                    }

                }

                &.right-nav {
                    z-index: 1;

                    li {
                        cursor: pointer;

                        &.lang {
                            font-size: 1.6rem;
                        }

                        .login-menu {
                            a {
                                padding: 16px 24px;
                                font-size: 1.6rem !important;
                                line-height: 16px;

                                &.login {
                                    font-size: 1.6rem !important;

                                    svg {
                                        margin-right: 6px;
                                        margin-bottom: 4px;

                                        @include media-breakpoint-up(lg){
                                            g>g>g>path{
                                                fill: $blue;
                                            }
                                        }
                                    }
                                }
                            }

                            ul {
                                display: none;
                                background-color: $turquoise;
                                display: none;
                                padding: 0 30px;
                                // position: fixed !important;
                                left: 0;

                                &::after {
                                    content: '';
                                    cursor: pointer;
                                    left: 24px;
                                    position: absolute;
                                    width: 0px;
                                    height: 0px;
                                    border: solid $turquoise;
                                    border-width: 0px 10px 10px 0;
                                    display: inline-block;
                                    padding: 4px;
                                    transform: rotate(225deg);
                                    top: -6px;
                                }

                                li {
                                    background: transparent;
                                    border: none;
                                    margin-bottom: 12px;
        
                                    &:first-child {
                                        padding-top: 30px;
                                    }
        
                                    &:last-child {
                                        padding-bottom: 20px;
                                    }
        
                                    a {
                                        color: $white;
                                        font-size: 2.4rem;
                                        font-weight: bold;
                                        font-family: 'unit-slab';
                                        text-decoration: none;
                                        padding: 0 !important;
                                        white-space: nowrap;
        
                                        svg {
                                            margin-top: -7px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    body.position-fixed {
        border: none;
    }

    header {
        nav {
            background-color: $grey-l3;
            height: 64px;
            padding-bottom: 0 !important;
            padding-top: 0 !important;
            z-index: 2;

            >.container-fluid {
                height: 100%;
                padding-left: 0;
                padding-right: 0;
                position: relative;
            }

            .navbar-brand {
                z-index: 2;

                img {
                    width: 130px;
                }
            }

            .mobile-right-nav {
                margin-right: 60px;

                .login-menu {
                    a {
                        padding: 10px 16px 16px !important;
                    }

                    ul {
                        background-color: $turquoise;
                        display: none;
                        padding: 10px 56px;
                        position: fixed !important;
                        right: 0;
                        left: unset;
                        top: 64px;
                        min-width: 40vw;

                        @include media-breakpoint-down(md) {
                            left: 0;
                            right: unset;
                            width: 100vw;
                        }

                        &::after {
                            content: '';
                            cursor: pointer;
                            right: 95px;
                            position: absolute;
                            width: 0px;
                            height: 0px;
                            border: solid $turquoise;
                            border-width: 0px 10px 10px 0;
                            display: inline-block;
                            padding: 4px;
                            transform: rotate(225deg);
                            top: -6px;
                        }

                        li {
                            background: transparent;
                            border: none;
                            margin-bottom: 12px;

                            &:first-child {
                                padding-top: 30px;
                            }

                            &:last-child {
                                padding-bottom: 20px;
                            }

                            a {
                                color: $white;
                                font-size: 2.4rem;
                                font-weight: bold;
                                font-family: 'unit-slab';
                                text-decoration: none;
                                padding: 0 !important;
                                white-space: nowrap;

                                svg {
                                    margin-top: -7px;
                                }
                            }
                        }
                    }
                }

                .lang {
                    color: $blue  !important;
                    font-size: 1.8rem;
                    font-weight: bold;
                }

            }

            #navbarNav {
                width: 100vw;
                transform: translateX(100%);
                transition: transform 0.3s linear;
                min-height: calc(100vh - 64px);
                background-color: $grey-l3;
                display: block;
                padding-top: 44px;

                &.hide-search{
                    .inner-container{
                        height: calc(100vh - 110px) !important;
                        overflow: hidden;
                        padding-bottom: 100px;
                    }
                }
                
                .inner-container {
                    height: 100%;
                    overflow-x: hidden;
                    padding-bottom: 100px;
                    

                    .navbar-nav:not(.right-nav) {
                        height: 100%;
                        width: 100%;
                        padding-left: 50px;
                        padding-right: 50px;

                        .nav-item {
                            a {
                                color: #3B9E93 !important;
                            }

                            font-family: "unit-slab";
                            font-size: 24px;
                            font-weight: bold;
                            border-top: 1px solid #ECEEF1;
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }

                .right-nav {
                    position: absolute;
                    top: -60px;
                    right: 50px;
                }
            }

            .menu-open {
                #navbarNav {
                    transform: translateX(0);
                    overflow: hidden;

                    &:not(.hide-search){
                        overflow: scroll;
                        padding-bottom: 100px;
                    }
                }
            }

            .navbar-toggler {
                position: absolute;
                right: 15px;
            }

            .subnav-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 100vw;
                transform: translateX(100%);
                padding: 0 0 0 50px;
                transition: transform 0.2s linear;
                background: $grey-l3;

                .subnav-header {
                    height: 200px;
                    position: relative;

                    .subnav-back {
                        color: #3B9E93;
                        font-size: 14px;
                        font-weight: 600;
                        margin-left: -28px;
                        padding-bottom: 100px;

                        svg {
                            transform: rotate(180deg);
                            transform-origin: center;
                        }
                    }

                    img {
                        height: 250px;
                        position: absolute;
                        right: -15px;
                        top: -30px;
                    }

                    .title {
                        color: #194458;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 24px;
                        position: absolute;
                        bottom: 100px;
                        left: 0;
                        z-index: 2;
                    }

                    .subtitle {
                        color: #194458;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 22px;
                        padding: 10px;
                        padding-left: 0;
                        position: absolute;
                        display: inline-block;
                        z-index: 1;
                        left: 0;
                        bottom: 55px;

                        &:after {
                            content: '';
                            position: absolute;
                            opacity: 0.8;
                            background-color: #F8F9FA;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            z-index: -2;
                        }
                    }
                }

                .subnav {
                    height: calc(100vh - 308px);
                    overflow: scroll;
                    padding-left: 27px;
                    padding-bottom: 100px;
                    width: 100vw;

                    .subnav-item {
                        counter-increment: section;
                        position: relative;

                        &::before{
                            content: '';
                            background-color: $turquoise;
                            left: -27px;
                            height: 1px;
                            position: absolute;
                            top: 22px;
                            width: 17px;
                        }

                        a {
                            color: #3B9E93;
                            font-size: 20px;
                            font-weight: 600;
                            letter-spacing: 0;
                            line-height: 48px;
                            opacity: 0;
                            transition: opacity 0.1s linear;
                            transition-delay: calc(0.3s + var(--delay) + s);
                        }
                    }
                }
            }

            .nav-item.sub-open .subnav-wrapper {
                transform: translateX(0);

                .subnav-item a {
                    opacity: 1;
                }
            }
        }
    }

    .alert-banner-wrapper+header nav #navbarNav {
        min-height: calc(100vh - 158px);

        .subnav {
            height: calc(100vh - 375px);
        }
    }
}