.notification-banner-block{
    margin-bottom: 90px;
    &>.row{
        background-color: $blue;
        color: $white;
        position: relative;
        &.greenline:after{
            content: "";
            position: absolute;
            width: 20px;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: $turquoise;
            @include media-breakpoint-down(md){
                width: 100%;
                bottom: auto;
                height: 20px;
            }
        }
    }
    .image{
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .content{
        padding: 50px;
        .title{
            margin-bottom: 1.5rem;
        }
        .large-font p{
            margin-bottom: 2.2rem;
        }
        a.btn{
            font-size: 2rem;
            font-weight: 700;
            line-height: 37px;
            min-height: 40px;
            margin-right: 32px;
            padding-left: 16px; 
            padding-right: 16px; 
            border: none;
            background-color: $turquoise;
            color: $white;
        }

        a.link{
            font-size: 2rem;
            font-weight: 700;   
            line-height: 1;
            color: $white;
            position: relative;
            text-decoration: none;
            &:after{
                content: '';
                position: absolute;
                width: 100%;
                left: 0;
                bottom: -5px;
                height: 2px;
                background-color: $turquoise;
            }
        }
    }
}