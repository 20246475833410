.page-newspage{
    .timestamp{
        margin-bottom: 20px;
    }
    .container-fluid{
        >.row{
            justify-content: center;
            padding-left: 48px;
            padding-right: 48px;
        }
    }
    .paragraph-block{
        img{
            margin-bottom: 48px;
            margin-top: 48px;
            &.full-width{
                margin-left: -8.3%;
                height: auto;
                width: calc(100% + 16.7%);
            }
        }
    }

    .alignnone {
        width: 100%;
        height: auto;
    }
}

@include media-breakpoint-down(md){
    .page-newspage{
        .paragraph-block{
            img{
                &.full-width{
                    margin-left: 0;
                    width: 100%;
                }
            }
        }
    }
}