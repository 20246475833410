.attention-block{
    .item-container{
        background-color: $green;
        min-height: 103px;
        font-weight: 700;
        padding: 8px 0;
        &::after{
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            bottom: -12px;
            z-index: 1;
            height: 24px;
            width: 24px;
            background-color: $green;
        }
        &:first-child{
            background-color: $yellow;
            &::after{
                background-color: $yellow;
            }
        }
        &:last-child{
            background-color: $turquoise-l1;
            &::after{
                content: none;
            }
        }
        .percentage{
            font-family: 'unit-slab';
            font-weight: bold;
            font-size: 3.2rem;
            line-height: 122%;
        }
    }
}