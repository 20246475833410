.page-hluthafastefnaindexpage{
    .filters{
        .filter-inner-container{
            border: 2px solid $grey-l1;
            border-radius: 4px;
            height: 48px;
            .select-container{
                select{
                    cursor: pointer;
                    appearance: none;
                    background-color: transparent;
                    font-size: 20px;
                    color: $grey-d1;
                    font-weight: 700;
                }
                img{
                    transform: translateY(-50%);
                    right: 8px;
                }
            }
        }
    }
    table{
        tr{
            &:hover{
                td{
                    background: $grey-l1;
                }
            }
            td{
                &:first-child{
                    width: 65%;
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .page-hluthafastefnaindexpage{
        .header-container{
            padding: 16px !important
        }
        .filters{
            padding: 0 !important;
            .filter{
                padding-left: 5px;
                padding-right: 5px;
                &:first-child{
                    padding-left: 8px;
                }
                &:last-child{
                    padding-right: 8px;
                }
            }
            form{
                padding: 0 24px !important;
                select{
                    font-size: 14px !important;
                    padding-right: 18px;
                }
                img{
                    right: -4px !important;
                }
            }
        }
        table{
            tr{
                td{
                    &:first-child{
                        width: auto;
                    }
                }
            }
        }
    }
}