.breadcrumbs-container{
    padding: 0 40px;
    .breadcrumbs{
        ul{
            li{
                a{
                    color: $blue-60;
                    font-size: 1.2rem;
                    font-weight: 600;
                    margin-right: 18px;
                }
                &:first-child{
                    &::before{
                        content: none;
                    }
                }
                &::before{
                    background-color: $turquoise;
                    content: '';
                    height: 2px;
                    left: -10px;
                    position: absolute;
                    top: 12px;
                    width: 2px;
                }
                &.active{
                    color: $blue;
                    font-size: 1.2rem;
                    font-weight: 600;
                    line-height: 2.4rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .breadcrumbs-container{
        padding: 0 16px;
    }
}