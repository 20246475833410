.page-hluthafastefnapage{
    .paragraph-block{
        .col-lg-8{
            padding: 0 48px !important;
        }
    }
}

@include media-breakpoint-down(md){
    .page-hluthafastefnapage{
        .hluthafastefna-page{
            >.row{
                padding: 16px 0  !important;
            }
        }
        .paragraph-block{
            .col-lg-8{
                padding: 0 16px !important;
            }
        }
    }
}