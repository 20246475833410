.bar-chart-cards-block{
    margin-bottom: 64px;
    .inner-container{
        background-color: $white;
        .chart-block{
            padding: 0 32px !important;
            .chart-inner-container{
                width: 100% !important;
                canvas{
                    margin: 30px 0 0 !important;
                }
            }
        }
        .text-container{
            padding-bottom: 88px;
            .title{
                color: $turquoise;
                margin-bottom: 16px;
                word-break: break-all;
            }
            p{
                margin-bottom: 16px;
            }
        }
        a.btn{
            background-color: $turquoise;
            border-radius: 2px;
            bottom: 32px;
            color: $white;
            font-size: 1.6rem;
            font-weight: 700;
            left: 32px;
            padding-top: 8px;
            height: 40px;
            width: calc(100% - 64px);
        }
    }
}

@include media-breakpoint-down(lg){
    .bar-chart-cards-block{
        .container-col{
            margin-top: 24px;
        }
    }
}