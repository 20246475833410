.paragraph-block{
    line-height: 145%;
    margin-bottom: 32px;
    .full-width{
        @include media-breakpoint-up(md){
            margin-top: 80px;
        }
    }
    h2, h3, h4{
        margin-top: 32px;
        &:first-child{
            margin-top: 0;
        }
    }
    h3{
        font-size: 3.2rem;
        line-height: 122%;
        margin-bottom: 16px;
    }
    h4{
        font-size: 2.4rem;
        line-height: 135%;
    }
    ol{
        margin-top: 32px;
        margin-bottom: 32px;
        padding-left: 24px;
        li{
            margin-bottom: 8px;
            padding-left: 12px;
            &::marker{
                color: $turquoise;
            }
        }
    }
    ul{
        li{
            list-style: none;
            margin-bottom: 8px;
            padding-left: 12px;
            position: relative;
            &::before{
                content: '';
                background-color: $turquoise;
                height: 8px;
                left: -12px;
                position: absolute;               
                top: 10px;
                width: 8px;
            }
           
        }
    }
}

@include media-breakpoint-down(md){
    .paragraph-block{
        font-size: 1.6rem;
        line-height: 150%;
        h3{
            font-size: 2.4rem;
        }
        h4{
            font-size: 2rem;
        }
    }
}