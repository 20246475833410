.latest-news-block{
    background-color: $blue;
    color: $white;
    
    .news{
        margin-top: -20px; 

        &:hover{
            h4{
                text-decoration: underline;
            }
        }

        .excerpt{
            p{
                font-size: 2rem;
            }
        }
    }
    a{
        color: $white;

        svg{
            margin-left: 16px;
        }
    }
}


@include media-breakpoint-up(md){
    .latest-news-block{
        padding: 80px 0;

        .title-container, .news{
            padding: 48px;
        }
    }
}

@include media-breakpoint-down(lg){
    .latest-news-block{
        .news{
            h4{
                font-size: 2.4rem;
            }
        }
    }
}

@include media-breakpoint-down(md){
    .latest-news-block{
        padding-bottom: 40px;
        padding-top: 40px;

        >.row {
            width: auto !important;

            >.col-12{
                padding: 0 !important;
            }
        }

        .title-container{
            margin-bottom: 20px;
        }

        .title-container, .news{
            padding: 16px;
        }

        .news{
            h4{
                margin-bottom: 16px;
            }

            a{
                font-size: 1.6rem;
            }

            .excerpt{
                p{
                    font-size: 1.6rem;
                }
            }
        }

        a{
            font-size: 2rem;
            margin-right: 32px;
            margin-top: 32px;
        }
    }
}