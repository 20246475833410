.link-block{
    margin-bottom: 32px;
    margin-top: 26px;
    .list-group{
        margin-bottom: 32px;
        &.links{
            border-bottom: 2px solid $blue !important;
            max-width: 825px;
            padding-bottom: 32px !important; 
            li{
                min-width: 100%;
            }
        }
        .list-group-item{
            background-color: transparent;
            min-height: 59px;
            padding: 15px 0 12px 46px;
            &.w-25{
                margin-bottom: -1px;
                margin-right: 12px;
                min-width: 194px;
                max-width: 194px;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                a{
                    border-bottom: 2px solid $font-color;
                }
            }
            &:hover{
                background-color: $list-group-hover;
                cursor: pointer;
            }
            svg{
                top: 10px;
                width: 32px;
            }
            a{
                font-size: 22px;
                line-height: 26px;
            }
        }
    }
    p{
        font-size: 22px;
    }
}

@include media-breakpoint-down(md){
    .link-block{
        .list-group{
            .list-group-item{
                padding: 16px 0 16px 40px;
                &.w-25{
                    max-width:  45%;
                    min-width: 45%;
                }
                a{
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
        p{
            font-size: 18px;
        }
    }
}