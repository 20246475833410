html{
    font-size: 62.75%;
}

body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Unit";
    font-size: 1.6rem;
    color: $blue;
    background-color: $grey-l2;

    .body-content{
        overflow-x: hidden;
    }

    &.position-fixed{
        border: 9px solid $blue;

        @include media-breakpoint-up(lg){
            header{
                margin-top: -9px;
                width: calc(100% + 18px);
                margin-left: -9px;
            }
        }
    }
}

#wagtail-userbar-trigger{
    font-size: 1.6rem!important;
}

.container-fluid{
    padding: 0 64px;
}

.super-h{
    font-size: calc(48px + (72 - 48) * ((100vw - 768px) / (1920 - 768)));
    line-height: calc(60px + (84 - 60) * ((100vw - 768px) / (1920 - 768)));

    @media (min-width: 1920px){
        font-size: 7.2rem;
        line-height: 8rem;
    }

    @media (max-width: 768px){
        font-size: 4.8rem;
        line-height: 115%;
    }
}

h1{
    font-family: 'unit-slab';
    font-size: 5.5rem;
    font-weight: bold;
    line-height: 116%;

    @include media-breakpoint-down(md){
        font-size: 3.2rem;
        line-height: 122%;
    }
}

h2{
    font-family: 'unit-slab';
    font-weight: bold;
    font-size: 4.8rem;
    line-height: 115%;

    @include media-breakpoint-down(md){
        font-size: 3.2rem;
        line-height: 122%;
    }
}

h3{
    font-family: 'unit-slab';
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 131%;

    @include media-breakpoint-down(md){
        font-size: 3.2rem;
        line-height: 122%;
    }
}

h4{
    font-family: 'unit-slab';
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 122%;

    @include media-breakpoint-down(md){
        font-size: 2.4rem;
    }
}

h5{
    font-family: 'unit-slab';
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 135%;

    &.secondary{
        font-family: 'Unit';
        line-height: 130%;
    }

    @include media-breakpoint-down(md){
        font-size: 1.8rem;
    }
}

h6{
    font-family: 'unit-slab';
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 150%;

    &.secondary{
        font-family: 'Unit';
    }
}

p{
    margin-bottom: 3.2rem;
}

.font-large{
    font-size: 2.2rem;
}

.style-none{
    list-style: none;
}

.bold{
    font-weight: 700;
}

.p-24{
    padding: 24px;
}

.p-32{
    padding: 32px;
}

.p-48{
    padding: 48px;
}

.px-48{
    padding-left: 48px;
    padding-right: 48px;
}

.mt-48{
    margin-top: 48px;
}

.mb-48{
    margin-bottom: 48px;
}

.me-48{
    margin-right: 48px;
}

.mt-32{
    margin-top: 32px;
}

.mb-32{
    margin-bottom: 32px;
}

@include media-breakpoint-up(md){
    body {
        &>section{
            min-height: 75vh;

            &.ch2{
                min-height: unset;
            }
        }
    }
}

@include media-breakpoint-down(lg){
    body{
        background-color: $grey-l3;
    }
}

@include media-breakpoint-down(md){
    .container-fluid{
        padding: 0 16px;
    }
}

.cc-color-override--667998236.cc-window{
    background-color: $blue !important;
    color: $white !important;
}

.cc-color-override--667998236 .cc-link{
    color: $white !important;
}

.cc-color-override--667998236 .cc-btn {
    color: $blue !important;
    background-color: $white !important;
}