.modal{
    .modal-dialog{
        .modal-content{
            background-color: $grey-l2;
            padding: 38px 38px 0 38px;
            button {
                font-size: 16px;
                right: 20px;
                top: 20px;
            }
            .modal-body{
                .form-page{
                    label{
                        margin-bottom: 0;
                    }
                    input{
                        margin-bottom: 10px;
                        width: 100%;
                    }
                }
                p{
                    a{
                        border-bottom: none;
                    }
                }
            }
            .modal-footer{
                .btn{
                    background-color: $turquoise;
                    border: none;
                    border-radius: 6px;
                    font-size: 1.6rem;
                    font-weight: 700;
                    min-height: 40px;
                    margin: 8px 0 16px;
                    padding: 8px 16px;
                }
            }
        }
    }
}