.file-block{
    .file-container{
        min-height: 56px;
        margin-bottom: -1px;
        &:hover{
            background-color: $grey-l3;
            cursor: pointer;
        }
        a{
            border-bottom: 1px solid $neutral-grey;
            border-top: 1px solid $neutral-grey;
            padding: 16px;
            font-size: 16px;
            font-weight: 600;
            color: $blue;
            width: 100%;
            height: 100%;
        }
    }
}