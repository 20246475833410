.staff-block{
    #staffTabContent, #staffTab{
        &::-webkit-scrollbar {
            width: 0;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
        }
        /* Optional: show position indicator in red */
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }
}