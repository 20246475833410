.two-col-block{
    margin-bottom: 64px;
    padding-bottom: 64px;
    padding-top: 64px;
    body.page-homepage &{
        .left-col{
            padding: 56px;
            p{
                font-size: 1.6rem;
                line-height: 150%;
            }
        }
        .left-col, .right-col{
            justify-content: center;
        }
    }
    .title-block{
        margin-bottom: 32px;
        .pre-title{
            color: $blue;
            font-size: 1.6rem;
            font-weight: 700;
            margin-bottom: 16px;
        }
    }
    img{
        height: auto;
        width: 100%;
    }
    p{
        font-size: 2.2rem;
        line-height: 145%;
    }
    h2{
        margin-bottom: 3.2rem;
    }
    h3{
        font-family: 'unit-slab';
        font-weight: bold;
        font-size: 3.2rem;
        line-height: 122%;
        @include media-breakpoint-down(md){
            font-size: 2.4rem;
        }
    }
    h4{
        font-size: 2.4rem;
        line-height: 135%;
        margin-bottom: 16px;
    }
    .chart-block{
        .chart-inner-container{
            width: 100% !important;
        }
    }
}