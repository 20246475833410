.good-to-know-block{
    padding-top: 32px;
    .title{
        margin-bottom: 24px;
    }
    .questions-container{
        ul{
            padding-left: 48px;
            li{
                cursor: pointer;
                color: $blue;
                font-size: 2rem;
                line-height: 64px;
                min-height: 64px;
                transition: max-height .3s linear;
                &.open{
                    max-height: 10000px;
                    transition: max-height .3s linear;
                    &::before{
                        transform: rotate(180deg);
                        transition: transform .3s linear;
                    }
                }
                .qa-question{
                    font-weight: 700;
                }
                &::before{
                    border: 7px solid transparent;
                    border-top: 0;
                    border-right-color: transparent;
                    border-bottom-color: $grey;
                    border-left-color: transparent;
                    content: '';
                    display: block;
                    height: 0px;
                    position: absolute;
                    left: -30px;
                    transform: rotate(90deg);
                    transition: transform .3s linear;
                    top: 28px;
                    width: 0px;
                }
                .answer{
                    display: none;
                    font-size: 1.8rem !important;
                    line-height: 160% !important;
                    font-weight: 400 !important;
                    p{
                        font-size: 1.8rem !important;
                        line-height: 160% !important;
                        font-weight: 400 !important;
                    }
                    ul{
                        li{
                            font-weight: 400;
                            line-height: 24px;
                            min-height: 44px;
                            font-size: 18px;
                            list-style: none;
                            position: relative;
                            &::before{
                                content: '';
                                width: 6px;
                                height: 6px;
                                background-color: $grey;
                                border-radius: 50%;
                                left: -14px;
                                top: 8px;
                                border: none;
                            }
                        }
                    }
                    .col-lg-8{
                        max-width: 100%;
                        min-width: 100%;
                    }
                    .container-fluid{
                        padding: 0 !important;
                    }
                    .table-block, .paragraph-block{
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                    table{
                        tr{
                            td, th{
                                line-height: 38px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .good-to-know-block{
        .questions-container{
            ul{
                li{
                    line-height: unset;
                    max-height: unset;
                    min-height: 64px;
                    &::before{
                        top: 10px;
                    }
                }
            }
        }
    }
}