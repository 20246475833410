.header-block{
    min-height: 600px;
    .content{
        padding: 32px;
    }
    .svg{
        width: 100%;
    }
    img{
        height: auto;
        width: 100%;
    }
    h1.super-h{
        margin-bottom: 32px;
    }
    .excerpt{
        line-height: 3.2rem;
    }
}

@media (min-width: 1260px){
    .header-block{
        .svg{
            position: absolute;
            top: -52px;
        }
    }
}
@include media-breakpoint-down(lg){
    .header-block{
        padding-bottom: 64px;
        padding-top: 64px;
    }
}