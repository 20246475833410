.loan-calculator-block{
    background-color: $blue;
    color: $white;
    margin-bottom: 48px;
    padding-bottom: 76px;
    padding-top: 64px;
    .top-row{
        margin-bottom: 48px;
        padding: 38px;
    }
    .iframe-row{
        margin-bottom: 48px;
    }
    .btn-row{
        a{
            color: $white;
            font-size: 2rem;
            font-weight: 700;
            height: 48px;
            margin-right: 24px;
            padding: 10px 32px 14px 32px;
            &.btn{
                background-color: $turquoise;
                &:last-child{
                    margin-right: 0 !important;
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .loan-calculator-block{
        .btn-row{
            a{
                font-size: 1.4rem;
                height: 32px;
                margin-right: 8px;
                padding: 6px 16px 8px 16px;
                &.first{
                    margin-bottom: 8px;
                }
            }
        }
    }
}