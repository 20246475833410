.button-list-block{
    a.btn{
        border: 2px solid $grey-l1;
        border-radius: 2px;
        color: $grey-d1;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 37px;
        min-height: 40px;
        margin-top: 16px;
        margin-right: 32px;
        padding-left: 16px; 
        padding-right: 16px; 
        &:first-child{
            border: none;
            background-color: $turquoise;
            color: $white;
        }
    }
}